import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {useDispatch, useSelector } from 'react-redux';
import Navbar from './components/navbar/Navbar';
import Registration from './components/registration/Registration';
import RegistrationSuccessPage from './components/registration/RegistrationSuccessPage';
import EmailVerificationSuccessPage from './components/registration/EmailVerificationSuccessPage';
import SchoolEmailRegistration from './components/registration/SchoolEmailRegistration';
import TermsOfService from './components/registration/TermsOfService';
import PrivacyPolicy from './components/registration/PrivacyPolicy';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import PasswordResetRequest from './components/auth/PasswordResetRequest';
import PasswordResetRequestSuccess from './components/auth/PasswordResetRequestSuccess';
import PasswordReset from './components/auth/PasswordReset';
import PasswordResetSuccess from './components/auth/PasswordResetSuccess';
import PasswordUpdate from './components/auth/PasswordUpdate';
import PasswordUpdateSuccess from './components/auth/PasswordUpdateSuccess';
import ItemsForSale from './components/itemsForSale/ItemsForSale';
import ItemForSaleDetail from './components/itemsForSale/ItemForSaleDetail';
import Dashboard from './components/itemsForSale/Dashboard';
import SchoolDashboard from './components/school/SchoolDashboard';
import CreateItemForSale from './components/itemsForSale/CreateItemForSale';
import Basket from './components/basket/Basket';
import Payment from './components/payment/Payment';
import Completion from './components/payment/Completion';
import PreOnboarding from './components/onboarding/PreOnboarding';
import OnboardingComplete from './components/onboarding/OnboardingComplete';
import ContactForm from './components/email/ContactForm';
import Footer from './components/footer/Footer';
import DeleteAccountGuide from './components/account/DeleteAccountGuide';
import DeleteAccountSuccess from './components/account/DeleteAccountSuccess';

import { loginSuccess } from './redux/slices/authenticationSlice';
import { fetchBasket } from './redux/slices/basketSlice';
import { fetchSchoolBySchoolUserId } from './redux/slices/schoolBySchoolUserIdSlice';


function App() {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData?.user?.id;

  const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);
  const basketItems = useSelector(state => state.basket.basket);
  const school = useSelector((state) => state.schoolBySchoolUserId.schoolBySchoolUserId);

  const isSchoolUser = userData?.user?.school_id !== null;
  const isSchoolUserEmailVerified = school?.email_verified === true && userData?.user?.school_id !== null;
  const hasTempPassword = school?.temp_school_password !== null;

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Check if the user is logged in upon component mount
    if (userData && !isLoggedIn) {
      // If userData exists, dispatch an action to update the state with the user data
      dispatch(loginSuccess(userData));
      dispatch(fetchBasket());
    }
  }, [dispatch, userData, isLoggedIn]);

  useEffect(() => {
    if (userId && isSchoolUser) {
      dispatch(fetchSchoolBySchoolUserId(userId));
    }
  }, [dispatch, userId]);

  const handleSearchQueryChange = (value) => {
    const schoolName = value.split(',')[0];
    setSearchQuery(schoolName);
  };

const renderSchoolDashboard = () => {
  if (!userData) return <Login />;
  if (!isSchoolUser) return <Navigate to="/" />;
  if (!isSchoolUserEmailVerified) return <SchoolEmailRegistration />;
  if (hasTempPassword) return <PasswordUpdate />;
  return <SchoolDashboard />;
};

const renderCreateItemForSale = () => {
  if (!userData) return <Login />;
  if (isSchoolUser && isSchoolUserEmailVerified) return <SchoolDashboard />;
  if (isSchoolUser && !isSchoolUserEmailVerified) return <SchoolEmailRegistration />;
  return <CreateItemForSale />;
};

const renderSchoolEmailRegistration = () => {
  if (!userData) return <Login />;
  if (!isSchoolUser) return <Navigate to="/" />;
  if (isSchoolUserEmailVerified) return <SchoolDashboard />;
  return <SchoolEmailRegistration />;
};

const renderHomePage = () => {
  if (hasTempPassword && isSchoolUserEmailVerified) return <PasswordUpdate />;
  if (userData && isSchoolUser && hasTempPassword && !isSchoolUserEmailVerified) return <SchoolEmailRegistration />;
  return <ItemsForSale searchQuery={searchQuery} onSearchQueryChange={handleSearchQueryChange}/>;
}

const renderLogout = () => {
  if (hasTempPassword && isSchoolUserEmailVerified) return <PasswordUpdate />;
  if (userData && isSchoolUser && hasTempPassword && !isSchoolUserEmailVerified) return <SchoolEmailRegistration />;
  return <Logout />;
}

const renderEmailVerificationSuccess = () => {
  if (!isSchoolUser || userData) return <Navigate to="/" />;
  return <EmailVerificationSuccessPage />
}

const renderContact = () => {
  if (hasTempPassword && isSchoolUserEmailVerified) return <PasswordUpdate />;
  if (isSchoolUser && hasTempPassword && !isSchoolUserEmailVerified) return <SchoolEmailRegistration />;
  return <ContactForm />
}

  return (
    <Router>
      <Navbar basketItemsCount={basketItems.length} searchQuery={searchQuery} onSearchQueryChange={handleSearchQueryChange} />
        <main>
          <Routes>
            <Route exact path="/" element={renderHomePage()} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/registration-success" element={<RegistrationSuccessPage />} />
            <Route path="/email-verification-success" element={renderEmailVerificationSuccess()} />
            <Route path="/school-email-registration" element={renderSchoolEmailRegistration()} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login" element={!userData ? <Login /> : <Navigate to="/" />} />
            <Route path="/logout" element={renderLogout()} />
            <Route path="/password-reset-request" element={<PasswordResetRequest />} />
            <Route path="/password-reset-request-success" element={<PasswordResetRequestSuccess />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
            <Route path="/password-update" element={userData ? <PasswordUpdate /> : <Login />} />
            <Route path="/password-update-success" element={<PasswordUpdateSuccess />} />
            <Route path="/dashboard" element={userData ? (isSchoolUser ? <SchoolDashboard /> : <Dashboard />) : <Login />} />
            <Route path="/school-dashboard" element={renderSchoolDashboard()} />
            <Route path="/create-item-for-sale" element={renderCreateItemForSale()} />
            <Route path="/item-for-sale/:itemForSaleId" element={<ItemForSaleDetail />} />
            <Route path="/basket" element={userData ? (isSchoolUser ? <SchoolDashboard /> : <Basket />) : <Login />} />
            <Route path="/payment" element={userData ? (isSchoolUser ? <SchoolDashboard /> : <Payment />) : <Login />} />
            <Route path="/payment-completion" element={<Completion />} />
            <Route path="/onboarding/pre" element={<PreOnboarding />} />
            <Route path="/onboarding/complete" element={<OnboardingComplete />} />
            <Route path="/contact" element={renderContact()} />
            <Route path="/delete-account-guide" element={<DeleteAccountGuide />} />
            <Route path="/delete-account-success" element={!userData ? <DeleteAccountSuccess /> : <Navigate to="/" />} />
          </Routes>
        </main>  
      <Footer />
    </Router>
  );
}

export default App;


