import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBasket } from './basketSlice';

// Create an async thunk for fetching stalls
export const fetchAddToBasket = createAsyncThunk('basket/fetchAddToBasket', async ({ userId, itemForSaleId }, { dispatch }) => {
  try {
    const response = await fetch(`/api/basket/user/${userId}/item/${itemForSaleId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    dispatch(fetchBasket(userId)); // update the basket items length in the navbar
    return data;

  } catch (error) {
    console.error('Error adding item to basket:', error);
    throw error;
  } 
});

const addToBasketSlice = createSlice({
  name: 'addToBasket',
  initialState: { status: 'idle', error: null, basket: null, message: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddToBasket.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddToBasket.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.basket = action.payload.basket;
        state.message = action.payload.message;
      })
      .addCase(fetchAddToBasket.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default addToBasketSlice.reducer;
