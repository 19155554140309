import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { Link } from 'react-router-dom';

const Hero = ({ isPageBlank }) => {

    const handleSellClick = () => {
        localStorage.setItem('sellRedirectRoute', '/create-item-for-sale');
    };

    return (
        <div className="px-4 my-3 text-center">
            <h1 className="display-5 fw-bold">Turn Pre-Loved Uniforms into Cash or School Donations!</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                    Clean Out, Cash In and Clothe Up! Sell your pre-loved uniforms with photos and prices or find affordable ones according to your needs. 
                    Easy listing, simple searching and smooth transactions. Swap, shop and connect with fellow school families today!
                </p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    {isPageBlank ? (
                        <Link to="/login" type="button" className="btn btn-outline-primary btn-lg px-4 gap-3 scrollto">Buy</Link>
                    ):(
                        <a href="#items-for-sale" type="button" className="btn btn-outline-primary btn-lg px-4 gap-3 scrollto">Buy</a>
                    ) }
                    <Link to='/create-item-for-sale' onClick={handleSellClick} type="button" className="btn btn-outline-primary btn-lg px-4">Sell</Link>
                </div>
            </div>
        </div>
    );
};

export default Hero;


