import React from 'react';

const DeleteAccountGuide = () => {
    return (
        <div className="container mt-5">
            <h1>How to Delete Your Account</h1>
            <p>1 - Login</p>
            <p>2 - On the navbar click on 'MY ACCOUNT'</p>
            <p>3 - Click on 'DASHBOARD'</p>
            <p>4 - On the bottom of the dashboard page click on the 'Delete My Account' red button </p>
        </div>
    );
};

export default DeleteAccountGuide;