import './Autocomplete.css'
import { useState } from 'react';

const Autocomplete = ({ suggestions, onSelect, onInputChange, searchTerm }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onInputChange(e); // Pass the event to the parent handler
    setShowDropdown(inputValue.trim().length > 0); // Show the dropdown only if there's some text
};

  const handleSelect = (suggestion) => {
    onSelect(suggestion); // Pass the selected suggestion to the parent handler
    setShowDropdown(false); // Hide the dropdown after selection
  };

  return (
    <div className="autocomplete form-floating">
      <input
        className="form-control"
        id="autocomplete"
        type="text"
        autoComplete="off"
        value={searchTerm} // Bind the current search term
        onChange={handleInputChange} // Use the handler to update state and trigger fetching
      />
      <label htmlFor="autocomplete">Search school by name</label>
      {showDropdown && (
        <div className="autocomplete-dropdown">
          {suggestions.map((s, index) => (
            <div key={index} onClick={() => handleSelect(s)}>
              {s}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
