const checkStripeConnectedAccountStatus = async (connectedAccount) => {
    try {
        // Check if the account is fully operational
        if (
            connectedAccount?.charges_enabled &&
            connectedAccount?.payouts_enabled &&
            connectedAccount?.requirements.eventually_due.length === 0
        ) {
            return "complete";
        }

        // Check if the account has no current or past due requirements, but has future requirements
        if (
            connectedAccount?.charges_enabled &&
            connectedAccount?.payouts_enabled &&
            connectedAccount?.requirements.eventually_due.length > 0
        ) {
            return "enabled";
        }

        // If none of the above, default to restricted
        return "restricted";

    } catch (error) {
        console.error('Error retrieving account:', error.message);
        return 'error';
    }
};

module.exports =  {
    checkStripeConnectedAccountStatus
};

