import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import { Link } from 'react-router-dom';

const PaymentCompletionPage = () => {
    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1>Payment Completed Successfully!</h1>
                <p>
                    Thank you for your purchase.<br/>
                    Please check your email and follow the instructions regarding the delivery of your item.<br/>
                    If you don't see the email in your inbox, please check your <strong>SPAM</strong> or <strong>JUNK</strong> folder.
                </p>
                <Link to="/" className="btn btn-primary">Go to Home</Link>
            </div>
        </div>
    );
};

export default PaymentCompletionPage;
