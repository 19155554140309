const stripeEstimatedFee = (amount) => {
    const feePercentage = 0.033; 
    const fixedFee = 0.20; 
    return ((amount * feePercentage) + fixedFee).toFixed(2); 
}

const calculatePrice = (price, donationPercentage) => {
    const platformFee = 0;
    const initialPrice = parseFloat(price, 10).toFixed(2);
    const initialPriceNumber = parseFloat(price, 10);
    const priceListed = (initialPriceNumber * 0.0342) + 0.20 + initialPriceNumber;
    const estimatedPaymentProcessFee = stripeEstimatedFee(priceListed)
    const roundedFinalPrice = Math.ceil(priceListed * 100) / 100;
    const platformCommission = (priceListed * platformFee).toFixed(2);
    const priceNet = roundedFinalPrice - stripeEstimatedFee(roundedFinalPrice) - platformCommission;
    const donationRate = parseFloat(donationPercentage, 10) / 100;
    const donation = (priceNet * donationRate).toFixed(2);
    const userEarning = (priceNet - donation).toFixed(2);

    return { initialPrice, estimatedPaymentProcessFee, roundedFinalPrice, platformCommission, donation, userEarning }

};

export default calculatePrice;
