import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterSchools } from '../../redux/slices/filterSchoolsSlice';

const SearchSchool = ({ onSchoolChange }) => {
    const dispatch = useDispatch();
    
    const filteredSchools = useSelector((state) => state.filterSchools.filteredSchools);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        dispatch(filterSchools(searchQuery));
    }, [dispatch, searchQuery]); 
    
    const handleSchoolChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
        onSchoolChange(value);
    };

    return (
        <>
            <input
                type="text"
                className="form-control"
                placeholder='Filter items by school name'
                id="school"
                onChange={handleSchoolChange}
                value={searchQuery}
                list="schools" // Connect input to datalist
                autoComplete="off"
                required
            />
            {/* Render filtered schools in a datalist */}
            <datalist id="schools">
                {filteredSchools.map((school) => (
                    <option key={school.id} value={`${school.name}, ${school.town}, ${school.postcode}`} />
                ))}
            </datalist>
        </>
    );
};

export default SearchSchool;
