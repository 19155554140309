import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForsSale
export const fetchTransferFunds = createAsyncThunk('transferFunds/fetchTransferFunds', async ({ userId, connectedAccountId, amount, itemsForSaleIds }) => {
  let response;
  try {
    response = await fetch(`/api/payment/transfer-funds/${connectedAccountId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, amount, itemsForSaleIds }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Transfer funds failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in transferFunds thunk', error);
    throw new Error(error.message || 'An error occurred during the API call');
  }
});

const transferFundsSlice = createSlice({
  name: 'transferFunds',
  initialState: { transferFunds: null, status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransferFunds.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(fetchTransferFunds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transferFunds = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(fetchTransferFunds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default transferFundsSlice.reducer;
