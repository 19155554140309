import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching stalls
export const filterSchools = createAsyncThunk('schools/filterSchools', async (searchQuery) => {
  if (!searchQuery) {
    // Return an empty array when there's no query
    return [];
  }
  
  const response = await fetch(`/api/schools/search?q=${searchQuery}`);
  const data = await response.json();
  return data;
});

const filterSchoolsSlice = createSlice({
  name: 'schools',
  initialState: { filteredSchools: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(filterSchools.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(filterSchools.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.filteredSchools = action.payload;
      })
      .addCase(filterSchools.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default filterSchoolsSlice.reducer;
