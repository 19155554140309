import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching stalls
export const fetchUserById = createAsyncThunk('user/fetchUserById', async (userId) => {
  const response = await fetch(`/api/users/${userId}`);
  const data = await response.json();
  return data;
});

const userByIdSlice = createSlice({
  name: 'user',
  initialState: { user: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userByIdSlice.reducer;
