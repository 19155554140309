import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
    return (
        <div className="container my-5">
            <h1>Dropop Marketplace Terms of Service</h1>
            <p>
                Welcome to Dropop Marketplace! These Terms of Service govern your use of the Dropop platform and services provided by Dropop Ltd 
                ("Dropop," "we," "us," or "our"). By accessing or using Dropop Marketplace, you agree to be bound by these Terms of Service.
            </p>
            <h2>1. Use of Dropop Marketplace</h2>
            <p>
                1.1 Eligibility: To use Dropop Marketplace, you must be at least 18 years old and capable of forming a legally binding contract.
            </p>
            <p>
                1.2 Registration: You may be required to create an account to access certain features of Dropop Marketplace. 
                You agree to provide accurate, current, and complete information during the registration process and to 
                update such information to keep it accurate, current, and complete.
            </p>
            <p>
                1.3 Account Security: You are responsible for maintaining the confidentiality of your account credentials
                and for all activities that occur under your account. 
                You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
            </p>
            <p>
                1.4 Prohibited Activities: You agree not to engage in any of the following prohibited activities in connection
                with your use of Dropop Marketplace:
            </p>   
            <ul>
                <li>Violating any applicable laws or regulations.</li>
                <li>Infringing the rights of others.</li>
                <li>Posting misleading, false, or inaccurate information.</li>
                <li>Engaging in any fraudulent or deceptive activities.</li>
                <li>Using Dropop Marketplace for any illegal or unauthorized purpose.</li>
                <li>Interfering with the operation of Dropop Marketplace or other users' use of Dropop Marketplace.</li>
                <li>Attempting to access or use another user's account without authorization.</li>
            </ul>
            <h2>2. Buying and Selling</h2>
            <p>
                2.1 Listing Items: Sellers may list used school uniforms for sale on Dropop Marketplace. 
                Sellers may also choose to donate a portion of the proceeds from the sale to a school related to the item.
            </p>
            <p>
                2.2 Payments: Payments for items purchased through Dropop Marketplace will be processed using Stripe. 
                Stripe retains £0.20 plus 3.3% approximately of the total transaction amount, including any donation amount.
            </p>
            <h2>3. Platform Commission</h2>
            <p>
                3.1 At present, Dropop does not charge a platform commission on transactions. 
                However, Dropop reserves the right to introduce platform commissions in the future, which will be communicated to users in advance.
            </p>
            <h2>4. Intellectual Property</h2>
            <p>
                4.1 Dropop Marketplace and its content, including but not limited to text, graphics, logos, images, and software,
                are the property of Dropop and are protected by intellectual property laws.
            </p>
            <h2>5. Privacy</h2>
            <p>5.1 Your use of Dropop Marketplace is subject to our <Link to="/privacy-policy">Privacy Policy, </Link>which governs the collection,
                use, and disclosure of your personal information.
            </p>
            <h2>6. Disclaimer of Warranties</h2>
            <p>
                6.1 Dropop Marketplace is provided on an "as is" and "as available" basis. 
                We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of Dropop Marketplace.
            </p>
            <h2>7. Limitation of Liability</h2>
            <p>
                7.1 To the fullest extent permitted by law, Dropop shall not be liable for any indirect, incidental, 
                special, consequential, or punitive damages, or any loss of profits or revenues, 
                whether incurred directly or indirectly, or any loss of data, use, goodwill, 
                or other intangible losses resulting from your access to or use of Dropop Marketplace.
            </p>
            <h2>8. Governing Law</h2>
            <p>8.1 These Terms of Service shall be governed by and construed in accordance with the laws of the United Kingdom.</p>
            <h2>9. Changes to Terms of Service</h2>
            <p>
                9.1 Dropop reserves the right to modify or update these Terms of Service at any time. 
                We will notify you of any changes by posting the new Terms of Service on Dropop Marketplace. 
                Your continued use of Dropop Marketplace after any such changes constitutes your acceptance of the new Terms of Service.
            </p>
            <h2>10. Contact Us</h2>
            <p>10.1 If you have any questions about these Terms of Service, please <Link to="/contact">contact us.</Link></p>
        </div>
    );
};

export default TermsOfService;
