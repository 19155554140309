import React from 'react';

const DeleteAccountGuide = () => {
    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1>Account Deleted</h1>
                <p>Your account has been successfully deleted. We're sorry to see you go.</p>
            </div>
        </div>
    );
};

export default DeleteAccountGuide;