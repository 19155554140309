import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchUserById } from '../../redux/slices/userByIdSlice';
import { removeAccount } from '../../redux/slices/removeAccountSlice';
import { fetchItemsForSaleByUserId } from '../../redux/slices/itemsForSaleByUserIdSlice';
import { fetchOrdersByUserId } from '../../redux/slices/ordersByUserIdSlice';
import { removeItemForSale } from '../../redux/slices/removeItemForSaleSlice';
import { fetchEarnings } from '../../redux/slices/earningsSlice';
import { createConnectedAccount } from '../../redux/slices/createConnectedAccountSlice';
import { fetchTransferFunds } from '../../redux/slices/transferFundsSlice';
import { fetchConnectedAccount } from '../../redux/slices/connectedAccountSlice';
// import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';
import { fetchPayouts } from '../../redux/slices/payoutsSlice';
import { logout } from '../../redux/slices/authenticationSlice';

import { checkStripeConnectedAccountStatus } from '../../utils/stripe'

import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';

function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [showRemoveItemForSaleConfirmation, setShowRemoveItemForSaleConfirmation] = useState(false);
    const [showRemoveAccountConfirmation, setShowRemoveAccountConfirmation] = useState(false);
    const [showSuccessfulWithdrawal, setShowSuccessfulWithdrawal] = useState(false);
    const [showFailedWithdrawal, setShowFailedWithdrawal] = useState(false);
    const [itemIdToRemove, setItemIdToRemove] = useState(null);
    const [earningToWithdraw, setEarningToWithdraw] = useState('0.00');
    const [withdrawnEarning, setWithdrawnEarning] = useState('0.00');
    const [transferFundsResult, setTransferFundsResult] = useState({});
    const [connectedAccountStatus, setconnectedAccountStatus] = useState('loading');

    const itemsForSaleByUserId = useSelector((state) => state.itemsForSaleByUserId.itemsForSaleByUserId);
    const userById = useSelector((state) => state.userById.user);
    const earnings = useSelector((state) => state.earnings.earnings);
    const orders = useSelector((state) => state.ordersByUserId.ordersByUserId);
    const connectedAccount = useSelector((state) => state.connectedAccount.connectedAccount);
    // const accountLink = useSelector((state) => state.createAccountLink.accountLink);
    const payouts = useSelector((state) => state.payouts.payouts);
    

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.user.id;
    const connectedAccountId = userById?.stripe_connected_account_id;

    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchUserById(userId));
            dispatch(fetchItemsForSaleByUserId(userId));
            dispatch(fetchOrdersByUserId(userId));
            dispatch(fetchEarnings(userId));
            if (connectedAccountId) {
                dispatch(fetchPayouts(connectedAccountId));
                dispatch(fetchConnectedAccount(connectedAccountId));
            }
            setLoading(false);
        }, 800);
    }, [dispatch,  userId, connectedAccountId]);

    // useEffect(() => {
    //     if (connectedAccountId && connectedAccountStatus === 'enabled') {
    //         dispatch(createAccountLink(connectedAccountId));
    //     }
    // }, [dispatch, connectedAccountId, connectedAccountStatus]);

    useEffect(() => {
        const savedResult = localStorage.getItem(`transferFundsResult_${userId}`);
        if (savedResult) {
            setTransferFundsResult(JSON.parse(savedResult));
        }
    }, [userId]);

    const calculateEarningToWithdraw = useCallback(() => {
        if (Array.isArray(earnings)) {
            const totalEarningToWithdraw = earnings.filter(earning => earning.fund_transferred === false)
                .reduce((accumulator, current) => accumulator + parseFloat(current.user_earning), 0).toFixed(2);
            if (earningToWithdraw !== totalEarningToWithdraw) {
                setEarningToWithdraw(totalEarningToWithdraw);
            }
        } else {
            if (earningToWithdraw !== '0.00') {
                setEarningToWithdraw('0.00');
            }
        }
    }, [earnings, earningToWithdraw]);

    const calculateWithdrawnEarning = useCallback(() => {
        if (Array.isArray(earnings)) {
            const totalWithdrawnEarning = earnings.filter(earning => earning.fund_transferred === true)
                .reduce((accumulator, current) => accumulator + parseFloat(current.user_earning), 0).toFixed(2);
            if (withdrawnEarning !== totalWithdrawnEarning) {
                setWithdrawnEarning(totalWithdrawnEarning);
            }
        } else {
            if (withdrawnEarning !== '0.00') {
                setWithdrawnEarning('0.00');
            }
        }
    }, [earnings, withdrawnEarning]);

    useEffect(() => {
        calculateEarningToWithdraw();
        calculateWithdrawnEarning();
    }, [earnings]);

    const handleRemoveItemForSale = (itemId) => {
        setItemIdToRemove(itemId);
        setShowRemoveItemForSaleConfirmation(true);
    };

    const confirmRemoveItem = () => {
        dispatch(removeItemForSale({ itemForSaleId: itemIdToRemove }));
        setShowRemoveItemForSaleConfirmation(false);
    };

    const cancelRemoveItem = () => {
        setItemIdToRemove(null);
        setShowRemoveItemForSaleConfirmation(false);
    };

    const handleRemoveAccount = () => {
        setShowRemoveAccountConfirmation(true);
    };

    const confirmRemoveAccount = async () => {
        try {
            setShowRemoveAccountConfirmation(false);
            setLoading(true);
    
            // Wrap setTimeout in a promise
            await new Promise((resolve) => setTimeout(resolve, 1500));
    
            await dispatch(logout());
            await dispatch(removeAccount());
            navigate('/delete-account-success');
        } catch (error) {
            console.error('Account deletion failed', error);
            alert('Failed to delete the account. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    
    const cancelRemoveAccount = () => {
        setShowRemoveAccountConfirmation(false);
    };

    const totalEarning = () => {
        if (Array.isArray(earnings)) { 
            return earnings.reduce((accumulator, current) => accumulator + parseFloat(current.user_earning), 0).toFixed(2);
        } else {
            return '0.00';
        }
    };

    const totalDonation = () => {
        if (Array.isArray(earnings)) { 
            return earnings.reduce((accumulator, current) => accumulator + parseFloat(current.school_donation), 0).toFixed(2);
        } else {
            return '0.00';
        }
    };

    useEffect(() => {
        const fetchConnectedAccountStatus = async () => {
            const status = await checkStripeConnectedAccountStatus(connectedAccount);
            setconnectedAccountStatus(status); // Update state once status is fetched
        };
        fetchConnectedAccountStatus(); // Call the function when the component mounts
    }, [connectedAccountId, connectedAccount]); // Dependency array ensures the effect runs when `connectedAccountId` changes

    const handleWithdrawEarnings = async () => {    
        try {
            const amount = (earningToWithdraw * 100).toFixed();
            const itemsForSaleIds = earnings.filter(earning => earning.fund_transferred === false).map(earning => earning.item_for_sale_id);

            if (!connectedAccountId) {
                await dispatch(createConnectedAccount(userId));
                await dispatch(fetchUserById(userId));
            }

            // Wait for the user data to be updated
            const updatedUserById = await dispatch(fetchUserById(userId));
            const newConnectedAccountId = updatedUserById.payload?.stripe_connected_account_id;

            if (!newConnectedAccountId) {
                console.log('Failed to create or fetch connected account.');
                return;
            }

            if (connectedAccountStatus === 'restricted') {
                navigate('/onboarding/pre');
                return;
            }

            // If onboarding is complete, proceed with withdrawal
            const resultAction = await dispatch(fetchTransferFunds({ userId, connectedAccountId, amount, itemsForSaleIds }));

            if (fetchTransferFunds.fulfilled.match(resultAction)) {
                await setEarningToWithdraw('0.00');
                await setWithdrawnEarning(totalEarning());
                await setShowSuccessfulWithdrawal(true);
                await localStorage.setItem(`transferFundsResult_${userId}`, JSON.stringify(resultAction.payload));
                const savedTransferFundsResult = localStorage.getItem(`transferFundsResult_${userId}`);
                await setTransferFundsResult(JSON.parse(savedTransferFundsResult));
    
            } else {
                await setShowFailedWithdrawal(true);
                throw new Error(resultAction.error.message || 'Transfer funds failed');
            }

        
        } catch (error) {
            console.error('Error during withdrawal process:', error.message);
            await setShowFailedWithdrawal(true);
            // Provide user-friendly error handling here
        }
    };
  
    const latestTransferFundsResult = transferFundsResult && (new Date(transferFundsResult.created_at * 1000) > new Date().setDate(new Date().getDate() - 1));

    let payoutsInTransit = [];
    if (!payouts.error) {
        payoutsInTransit = payouts.filter(payout => new Date(payout.arrival_date * 1000) >= new Date());
    }

    const isPayoutInTransit = payoutsInTransit.length > 0;

    if (loading) {
        return (
            <div className="container py-5 d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    
    return (
        <div className="container-fluid">
                <section>
                    <div className="container py-5">
                        <h3>My Earnings</h3>
                        {connectedAccountStatus === 'enabled' ? (
                            // <p className="alert alert-warning" role="alert">
                            //     This account requires verification. <a href={`${accountLink.url}`}>Click here</a> to avoid withdrawal interruption.
                            // </p>
                            <p></p>
                        ):(
                            <p></p>
                        )}
                        <table className="table">
                                <>
                                <thead>
                                    <tr>
                                        <th scope="col">total earning</th>
                                        <th scope="col">total donation</th>
                                        <th scope="col">withdrawn</th>
                                        <th scope="col">to withdraw</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>£{totalEarning()}</td>
                                        <td>£{totalDonation()}</td>
                                        <td>£{withdrawnEarning}</td>
                                        <td>
                                            <span className={`${earningToWithdraw === '0.00' ? '' : 'bg-success text-white p-1 fw-bold'}`}>£{earningToWithdraw}</span>
                                        </td>
                                        <td>
                                            <button onClick={handleWithdrawEarnings} className={`${earningToWithdraw === '0.00' ? 'disabled' : ''} btn btn-outline-primary btn-sm`}>Withdraw</button>
                                        </td>
                                    </tr>
                                </tbody>
                                </>
                        </table>
                        {latestTransferFundsResult && 
                            <p className="text-bg-warning">
                                {`The latest withdrawal of £${transferFundsResult.amount / 100} 
                                on ${new Date(transferFundsResult.created_at * 1000).toLocaleDateString('en-GB')} 
                                is expected to reach your bank account soon.`}
                            </p>
                        }
                        {isPayoutInTransit && 
                            <>
                                {payoutsInTransit.map(payout => (
                                    <p 
                                        key={payout.id} 
                                        className="text-bg-success"
                                    >
                                        {`The amount of £${payout.amount / 100} 
                                        is expected to reach your bank account on 
                                        ${new Date(payout.arrival_date * 1000).toLocaleDateString('en-GB')}.`}
                                    </p>
                                ))}
                            </>
                        }
                    </div>
                    <Modal show={showSuccessfulWithdrawal} onHide={() => setShowSuccessfulWithdrawal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Withdrawal successful!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Your withdrawal amount will be transferred soon.</Modal.Body>
                    </Modal>
                    <Modal show={showFailedWithdrawal} onHide={() => setShowFailedWithdrawal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Oops, Something went wrong!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Please try again later.</Modal.Body>
                    </Modal>
                </section>
                <section>
                    <div className="container py-5">
                        <h3>My Items</h3>
                        {itemsForSaleByUserId.length > 0 ? (
                            <>
                            <table className="table">
                                <tbody>
                                    {itemsForSaleByUserId.map((itemForSaleByUserId) => (     
                                        <tr key={itemForSaleByUserId.id} className={`${itemForSaleByUserId.to_removal === true ? 'd-none' : ''}`}>
                                            <td>{itemForSaleByUserId.school_name}</td>
                                            <td><img src={`https://storage.googleapis.com/dropop/uploads/${itemForSaleByUserId.images[0]}`} className="img-fluid" width="50px" height="auto" alt={itemForSaleByUserId.item_name} /></td> 
                                            <td>{itemForSaleByUserId.item_name}</td>
                                            <td><span className="cell-text-truncate text-truncate">{itemForSaleByUserId.item_description}</span></td>
                                            <td>£{itemForSaleByUserId.price}</td>
                                            <td> {itemForSaleByUserId.sold ? (
                                                <p>SOLD</p>
                                            ) : (
                                                <button onClick={() => handleRemoveItemForSale(itemForSaleByUserId.id)} type="button" className="btn-close" aria-label="Close"></button>
                                            )}
                                            </td>
                                        </tr> 
                                    ))}
                                </tbody>
                            </table> 
                            </>
                        ):(
                            <div className="">
                                No items listed
                            </div>
                        )}    
                    </div>
                    <Modal show={showRemoveItemForSaleConfirmation} >
                        <Modal.Header closeButton onClick={cancelRemoveItem}>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to remove this item?</Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={cancelRemoveItem}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={confirmRemoveItem}>Confirm</button>
                        </Modal.Footer>
                    </Modal>
                </section>

                <section>
                    <div className="container py-5">
                        <h3>My Orders</h3>      
                        {orders.length > 0 ? (
                            <>
                            <table className="table">
                                <tbody>
                                    {orders.map((order) => (
                                        <React.Fragment key={order.order_id}>     
                                            {order.items_for_sale.map((item) => ( 
                                                <tr key={item.item_for_sale_id}>
                                                    <td>{new Date(order.created_at).toLocaleDateString('en-GB')}</td>
                                                    <td>{item.school_name}</td>
                                                    {item.images.map((image, index) => (
                                                        <td key={image + index}>
                                                            <img 
                                                                src={`https://storage.googleapis.com/dropop/uploads/${image}`} 
                                                                className="img-fluid" 
                                                                width="50px" 
                                                                height="auto" 
                                                                alt={item.item_name} 
                                                            />
                                                        </td>
                                                    ))}
                                                    <td>{item.item_name}</td>
                                                    <td>£{item.price}</td>
                                                </tr>
                                            ))}      
                                        </React.Fragment>          
                                    ))} 
                                </tbody>
                            </table>  
                            </>
                        ):(
                            <div className="">
                                No orders
                            </div>
                        )}     
                    </div>
                </section>
                <section>
                    <hr/>
                    <div className="container py-5">
                        <button onClick={() => handleRemoveAccount()} type="button" className="btn btn-danger disabled">Delete My Account</button>
                    </div>
                    <Modal show={showRemoveAccountConfirmation} >
                        <Modal.Header closeButton onClick={cancelRemoveAccount}>
                            <Modal.Title>Confirm  Account Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to remove your account?
                            All your data will be removed, including your items and orders.
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={cancelRemoveAccount}>Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={confirmRemoveAccount}>Confirm Deletion</button>
                        </Modal.Footer>
                    </Modal>
                </section>
        </div>
    );
}

export default Dashboard;


