import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBasket } from './basketSlice';

// Create an async thunk for fetching stalls
export const removeFromBasket = createAsyncThunk(
  'basket/removeFromBasket',
  async ({ userId, basketItemId }, { dispatch }) => {
    try {
      const response = await fetch(`/api/basket/user/${userId}/item/${basketItemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      // Fetch updated basket data after item removal
      dispatch(fetchBasket(userId));
      return data; // Assuming the response contains the updated basket data
    } catch (error) {
      console.error('Error removing item from basket:', error);
      throw error; // Propagate the error
    }
  }
);

const removeFromBasketSlice = createSlice({
  name: 'basket',
  initialState: { status: 'idle', error: null, basket: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeFromBasket.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeFromBasket.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(removeFromBasket.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default removeFromBasketSlice.reducer;
