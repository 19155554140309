import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching stalls
export const registerUser = createAsyncThunk('registration/registerUser', async (userData) => {
  const response = await fetch('/api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }

  return data
});

const registrationSlice = createSlice({
  name: 'registration',
  initialState: { status: 'idle', error: null },
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.registration = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetError } = registrationSlice.actions;
export default registrationSlice.reducer;
