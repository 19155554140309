import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';

import { updatePassword } from '../../redux/slices/updatePasswordSlice';
import { fetchUserById } from '../../redux/slices/userByIdSlice';

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, error: resetError } = useSelector((state) => state.updatePassword);
  const userById = useSelector((state) => state.userById.user);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData?.user?.id;
  const email = userData?.user?.email;
  const hasTempPassword = userById?.temp_school_password !== null;

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (status === 'succeeded') {
        navigate('/password-update-success');
    }
  }, [status, navigate]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserById(userId));
    }
  }, [dispatch, userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await dispatch(updatePassword({ email, newPassword }));
    } catch (error) {
      setError(resetError || 'Failed to reset password');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mt-5 justify-content-center">
        <div className="col-lg-4">
          <h2 className="text-center mb-5">Update Password</h2>
          {hasTempPassword && 
          <div className="text-center alert alert-primary mb-5" role="alert">
            <h4>You're one step away!</h4> 
            <p>Please update your password below to keep your account safe and secure.</p>
          </div>
          }
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="newPassword">New Password</label>
              <input
                className="form-control"
                type="password"
                id="newPassword"
                name="newPassword"
                autoComplete="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
              <input
                className="form-control"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="text-danger">{error}</div>}
            <button type="submit" className="btn btn-primary w-100" disabled={status === 'loading'}>
              {status === 'loading' ? 'Updating...' : 'Update Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdate;
