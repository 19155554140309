import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching registered schools
export const fetchRegisteredSchools = createAsyncThunk('registeredSchools/fetchregisteredSchools', async () => {
  const response = await fetch('/api/schools/registered-schools');
  const data = await response.json();
  return data;
});

const registeredSchoolsSlice = createSlice({
  name: 'registeredSchools',
  initialState: { schools: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegisteredSchools.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegisteredSchools.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.registeredSchools = action.payload;
      })
      .addCase(fetchRegisteredSchools.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default registeredSchoolsSlice.reducer;
