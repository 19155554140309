import registrationReducer from '../redux/slices/registrationSlice';
import registerSchoolEmailReducer from '../redux/slices/registerSchoolEmailSlice';
import authenticationReducer from './slices/authenticationSlice';
import userByIdReducer from '../redux/slices/userByIdSlice';
import removeAccountReducer from './slices/removeAccountSlice';
import itemsForSaleReducer from '../redux/slices/itemsForSaleSlice';
import itemsForSaleByUserIdReducer from '../redux/slices/itemsForSaleByUserIdSlice';
import itemForSaleByIdReducer from './slices/itemForSaleByIdSlice';
import createItemForSaleReducer from '../redux/slices/createItemForSaleSlice';
import removeItemForSaleReducer from '../redux/slices/removeItemForSaleSlice';
import schoolsReducer from '../redux/slices/schoolsSlice';
import registeredSchoolsReducer from '../redux/slices/registeredSchoolsSlice';
import schoolBySchoolUserIdReducer from '../redux/slices/schoolBySchoolUserIdSlice';
import filterSchoolsReducer from '../redux/slices/filterSchoolsSlice';
import itemsReducer from '../redux/slices/itemsSlice';
import basketReducer from '../redux/slices/basketSlice';
import addToBasketReducer from '../redux/slices/addToBasketSlice';
import removeFromBasketReducer from '../redux/slices/removeFromBasketSlice';
import ordersByUserIdReducer from '../redux/slices/ordersByUserIdSlice';
import earningsReducer from '../redux/slices/earningsSlice';
import schoolEarningsReducer from '../redux/slices/schoolEarningsSlice';
import createConnectedAccountReducer from './slices/createConnectedAccountSlice';
import createAccountLinkReducer from './slices/createAccountLinkSlice';
import checkConnectedAccountStatusReducer from './slices/checkConnectedAccountStatusSlice';
import connectedAccountReducer from './slices/connectedAccountSlice';
import transferFundsReducer from './slices/transferFundsSlice';
import payoutsReducer from './slices/payoutsSlice';
import contactReducer from './slices/contactSlice';
import requestPasswordResetReducer from './slices/requestPasswordResetSlice';
import resetPasswordReducer from './slices/resetPasswordSlice';
import updatePasswordReducer from './slices/updatePasswordSlice';


const rootReducer = {
    registration: registrationReducer,
    registerSchoolEmail: registerSchoolEmailReducer,
    authentication: authenticationReducer,
    userById: userByIdReducer,
    removeAccount: removeAccountReducer,
    itemsForSale: itemsForSaleReducer,
    itemsForSaleByUserId: itemsForSaleByUserIdReducer,
    itemForSaleById: itemForSaleByIdReducer,
    createItemForSale: createItemForSaleReducer,
    removeItemForSale: removeItemForSaleReducer,
    schools: schoolsReducer,
    registeredSchools: registeredSchoolsReducer,
    schoolBySchoolUserId: schoolBySchoolUserIdReducer,
    filterSchools: filterSchoolsReducer,
    items: itemsReducer,
    basket: basketReducer,
    addToBasket: addToBasketReducer,
    removeFromBasket: removeFromBasketReducer,
    ordersByUserId: ordersByUserIdReducer,
    earnings: earningsReducer,
    schoolEarnings: schoolEarningsReducer,
    createConnectedAccount: createConnectedAccountReducer,
    createAccountLink: createAccountLinkReducer,
    checkConnectedAccountStatus: checkConnectedAccountStatusReducer,
    connectedAccount: connectedAccountReducer,
    transferFunds: transferFundsReducer,
    payouts: payoutsReducer,
    contact: contactReducer,
    requestPasswordReset: requestPasswordResetReducer,
    resetPassword: resetPasswordReducer,
    updatePassword: updatePasswordReducer
}


export default rootReducer;