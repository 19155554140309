import 'bootstrap/dist/css/bootstrap.min.css';
import './ItemsForSale.css'

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemForSaleById } from '../../redux/slices/itemForSaleByIdSlice';
import { fetchAddToBasket } from '../../redux/slices/addToBasketSlice';


function ItemForSaleDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { itemForSaleId } = useParams();

    const itemForSale = useSelector((state) => state.itemForSaleById.itemForSale);
    const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);

    const [addedToBasketMessage, setAddedToBasketMessage] = useState('');
    const [itemAlreadyInBasketMessage, setItemAlreadyInBasketMessage] = useState('');
    const [mainImage, setMainImage] = useState('');

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;
    const isUserSchool = userData !== null && userData?.user?.school_id !== null;

    useEffect(() => {
        dispatch(fetchItemForSaleById(itemForSaleId));
    }, [dispatch, itemForSaleId]);

    useEffect(() => {
        if (itemForSale && itemForSale.length > 0) {
            setMainImage(`https://storage.googleapis.com/dropop/uploads/${itemForSale[0].images[0]}`);
        }
    }, [itemForSale]);

    // Event handler for adding item to basket
    const handleAddToBasket = async () => {
        if (!isLoggedIn) {
            // Redirect to login page with current location as state
            navigate('/login');
            return;
        }

        // Dispatch the addToBasket action with the itemForSaleId
        // Check if the item is already in the basket
        const response = await dispatch(fetchAddToBasket({ userId, itemForSaleId }));
        
        if (response.payload.message === 'Item already in the basket') {
            setItemAlreadyInBasketMessage(response.payload.message);
            setTimeout(() => {
                setItemAlreadyInBasketMessage('');
            }, 3000);
        } else {
            setAddedToBasketMessage(`Item added to basket`);
            setTimeout(() => {
                setAddedToBasketMessage('');
            }, 3000);
        }
    };

    const handleThumbnailClick = (image) => {
        setMainImage(`https://storage.googleapis.com/dropop/uploads/${image}`);
    };

    if (!itemForSale) {
        return <div>Loading...</div>; // Or a loading spinner or some placeholder content
    }

    return (
        <div className="container-fluid">
            {addedToBasketMessage && <div className="alert alert-success mt-2">{addedToBasketMessage}</div>}
            {itemAlreadyInBasketMessage && <div className="alert alert-warning mt-2">{itemAlreadyInBasketMessage}</div>}
            <section className="py-1">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-md-6">
                            <div className="text-lg-end" >
                                <div className="main-image-wrapper">
                                    <img 
                                        id="mainImage" 
                                        src={mainImage} 
                                        className="main-image" 
                                        alt={itemForSale[0].item_name} 
                                    />
                                </div>
                                <div className="thumbnails d-flex justify-content-center">
                                    {itemForSale[0].images.slice(0, 5).map((image, index) => (
                                        <img 
                                            key={index} 
                                            src={`https://storage.googleapis.com/dropop/uploads/${image}`} 
                                            alt={`Thumbnail ${index + 1}`} 
                                            className="thumbnail-square m-1" 
                                            onClick={() => handleThumbnailClick(image)} 
                                        />
                                    ))}
                                </div>
            
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fs-5 fw-medium mb-0">{itemForSale[0].school_name}</div>
                            <div className="fs-6 fw-light mt-0 mb-1">{itemForSale[0].school_town}</div>
                            <div className="fs-3 fw-bold mb-1">£{itemForSale[0].price}</div>
                            <div className="fs-5 fw-bolder text-capitalize mb-2">{itemForSale[0].item_name}</div>
                            <p className="lead">{itemForSale[0].item_description}</p>
                            <div className="d-flex">
                                <button 
                                    onClick={() => handleAddToBasket(itemForSaleId)} 
                                    className={`${isUserSchool ? 'disabled' : ''} btn btn-outline-dark flex-shrink-0`}
                                >
                                    Add to Basket
                                </button> 
                            </div>
                            {isUserSchool && 
                                <p className="text-danger">You need to be logged in with a personal account to proceed with the purchase.</p>
                            }
                        </div>
                    </div>
                </div>
            </section>    
        </div>
    );
}

export default ItemForSaleDetail;


