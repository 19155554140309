import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for remove account
export const removeAccount = createAsyncThunk(
  'account/removeAccount', 
  async () => {
    try {
      await fetch(`/api/users/delete-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      console.error('Error removing account', error);
      throw error; // Propagate the error
    }
  }
);

const removeAccountSlice = createSlice({
  name: 'account',
  initialState: { status: 'idle', error: null, user: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeAccount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeAccount.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(removeAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default removeAccountSlice.reducer;
