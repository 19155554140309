import React from 'react';

const RegistrationSuccessPage = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    return (
        !userData ? (
            <div className="container mt-5">
                <div className="text-center">
                    <h1>Registration Successful</h1>
                    <p>Thank you for registering with us!</p>
                    <p>An email has been sent to your email address. Please follow the instructions to verify your email.</p>
                    <p>If you haven't received the email, please check your <strong>SPAM</strong> or <strong>JUNK</strong> folder.</p>
                    <p>Once your email is verified, you'll be able to log in and access your account.</p>
                </div>
            </div>
        ) : (
            <div className="container mt-5">
                <div className="text-center">
                    <h1>Email Added Successfully</h1>
                    <p>An email has been sent to your email address. Please follow the instructions to verify your email.</p>
                    <p>If you haven't received the email, please check your <strong>SPAM</strong> or <strong>JUNK</strong> folder.</p>
                </div>
            </div>
        )     
    );
};

export default RegistrationSuccessPage;
