import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div className="container my-5">
            <h1>Dropop Marketplace Privacy Policy</h1>
            <p>Last Updated: 30/7/24</p>
            <p>
                This Privacy Policy describes how Dropop Ltd ("Dropop," "we," "us," or "our") 
                collects, uses, and shares information about you when you use our marketplace platform ("Dropop Marketplace") and our services.
            </p>
            <h2>1. Information We Collect</h2>
            <p>
                1.1 Personal Information: When you create an account, buy or sell items, or otherwise use Dropop Marketplace,
                we may collect the following personal information:
            </p>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Mailing address</li>
                <li>Phone number</li>
                <li>Payment information</li>
            </ul>
            <p>
                1.2 Usage Information: We may collect information about your interactions with Dropop Marketplace, including:
            </p>
            <ul>
                <li>Browsing history</li>
                <li>Search queries</li>
                <li>Purchase history</li>
                <li>Communication with other users</li>
                <li>Device and usage information</li>
            </ul>
            <p>
                1.3 Cookies and Tracking Technologies: 
                We use cookies and similar tracking technologies to collect information about your use of Dropop Marketplace. 
                This helps us improve our services and provide a better user experience.
            </p>
            <h2>2. How We Use Your Information</h2>
            <p>
                2.1 Provide and Improve Our Services: 
                We use your information to provide, maintain, and improve Dropop Marketplace and our services, 
                including processing transactions and providing customer support.
            </p>
            <p>
                2.2 Communicate with You: 
                We may use your information to communicate with you about your account, 
                transactions, and any updates or changes to our services.
            </p>
            <p>
                2.3 Marketing and Advertising: With your consent, we may use your information to send you promotional materials and advertisements.
            </p>
            <p>
                2.4 Legal Compliance: 
                We may use your information to comply with applicable laws, regulations, and legal processes. 
            </p>
            <h2>3. Sharing Your Information</h2>
            <p>
                3.1 Service Providers: 
                We may share your information with third-party service providers who perform services on our behalf, 
                such as payment processing, data analysis, and customer support.
            </p>
            <p>
                3.2 Legal Requirements: 
                We may disclose your information if required to do so by law or in response to valid requests by public authorities.
            </p>
            <p>
                3.3 Business Transfers: 
                In the event of a merger, acquisition, or sale of all or a portion of our assets, 
                your information may be transferred to the acquiring entity.
            </p>
            <h2>4. Your Choices</h2>
            <p>
                4.1 Account Information: 
                You can access and update your account information by logging into your account on Dropop Marketplace.
            </p>
            <p>
                4.2 Marketing Communications: 
                You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications or by contacting us directly.
            </p>
            <p>
                4.3 Cookies: 
                Most web browsers are set to accept cookies by default. 
                You can adjust your browser settings to remove or reject cookies, but this may affect the functionality of Dropop Marketplace.
            </p>
            <h2>5. Security</h2>
            <p>
                5.1 We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no internet-based service can be completely secure, 
                and we cannot guarantee the absolute security of your information.
            </p>
            <h2>6. Children's Privacy</h2>
            <p>
                6.1 Dropop Marketplace is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. 
                If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
            </p>
            <h2>7. Changes to This Privacy Policy</h2>
            <p>
                7.1 We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on Dropop Marketplace. 
                Your continued use of Dropop Marketplace after any changes constitutes your acceptance of the new Privacy Policy.
            </p>
            <h2>8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please <Link to="/contact">contact us.</Link></p>
            <br/>
            <p>By using Dropop Marketplace, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>
        </div>
    );
};

export default PrivacyPolicy;
