import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';
import { fetchUserById } from '../../redux/slices/userByIdSlice';

const PreOnboarding = () => {
	const dispatch = useDispatch();

	const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.user.id;
	
	const userById = useSelector((state) => state.userById.user);
	const connectedAccountId = userById?.stripe_connected_account_id;

	useEffect(() => {
        dispatch(fetchUserById(userId));
    }, [dispatch,  userId]);

	const handleRedirectOnboarding = async () => {    
		try {
			const linkResponse = await dispatch(createAccountLink(connectedAccountId));
			const accountLink = linkResponse.payload.url;

			if (accountLink) {
				window.location.href = accountLink;
				return;
			} else {
				throw new Error('Account link is not available');
			}
		} catch (error) {
			console.error('Error during withdrawal process:', error.message);
			// Provide user-friendly error handling here
		}
	};

	return (
		<div className="container mt-5">
			<div className="text-center">
				<h2>Pre-Onboarding</h2>
				<p>Since this is your first earnings withdrawal, we'll need some personal information and your bank account details. Please click the button below to proceed:</p>
				<button onClick={handleRedirectOnboarding} className="btn btn-primary btn-sm">Withdraw</button>
			</div>
		</div>
	);
};

export default PreOnboarding;
