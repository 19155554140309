import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching payout
export const fetchPayouts = createAsyncThunk('payouts/fetchPayouts', async (connectedAccountId) => {
  const response = await fetch(`/api/payment/payout/${connectedAccountId}`);
  const data = await response.json();
  return data;
});

const payoutsSlice = createSlice({
  name: 'payouts',
  initialState: { payouts: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayouts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPayouts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.payouts = action.payload;
      })
      .addCase(fetchPayouts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default payoutsSlice.reducer;
