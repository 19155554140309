import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk for sending email
export const fetchContact = createAsyncThunk('contact/fetchContact', async (formData) => {
  const response = await fetch('/api/send-email/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }
  return data
});

const contactSlice = createSlice({
  name: 'contact',
  initialState: { status: 'idle', error: null },
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contact = action.payload;
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default contactSlice.reducer;
