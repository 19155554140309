import React from 'react';
import { Link } from 'react-router-dom'; // If you're using React Router

const OnboardingComplete = () => {
	return (
		<div className="container mt-5">
			<div className="text-center">
				<h2>Onboarding Complete!</h2>
				<p>
					Thank you for completing the onboarding process.<br/>
					Your account is now fully set up, and you can begin withdrawing your earnings.
				</p>
							
				{/* Links to navigate to other parts of your application */}
				<div>
					<Link to="/dashboard" className="btn btn-primary btn-sm">Go to Dashboard to withdraw your earnings</Link>
					{/* <Link to="/support"> */}
						{/* <button>Contact Support</button> Link to support or help section */}
					{/* </Link> */}
				</div>
			</div>
		</div>
  );
};

export default OnboardingComplete;

