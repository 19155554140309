import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

import React from 'react';
import { Link, useLocation } from 'react-router-dom';


function Footer() {
    const location = useLocation();
    const isContactPage = location.pathname === '/contact';

    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer mt-auto pt-5">
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                <li className="nav-item"><Link to="/" className="nav-link px-2 text-muted">Home</Link></li>
                {!isContactPage && <li className="nav-item"><Link to="/contact" className="nav-link px-2 text-muted">Contact</Link></li>}
            </ul>
            <p className="text-center text-muted">© {currentYear} Dropop, Inc</p>
        </footer>
    );
}


export default Footer;



