import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerSchoolEmail } from '../../redux/slices/registerSchoolEmailSlice';
import { fetchSchoolBySchoolUserId } from '../../redux/slices/schoolBySchoolUserIdSlice';


const SchoolEmailRegistration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user.id

    useEffect(() => {
        dispatch(fetchSchoolBySchoolUserId(userId));
    }, [dispatch, userId]);

    const school = useSelector((state) => state.schoolBySchoolUserId.schoolBySchoolUserId);
    const registrationStatus = useSelector((state) => state.registerSchoolEmail.status);
    const registrationError = useSelector((state) => state.registerSchoolEmail.error);

    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const actionResult = await dispatch(registerSchoolEmail({ 
                email, 
                schoolName: school?.name, 
                username: school?.username,
                stripeConnectedAccountId: school?.stripe_connected_account_id 
            }));
            // Check if the  email registration action succeeded
            if (registerSchoolEmail.fulfilled.match(actionResult)) {
                // Email egistration successful, navigate to success page
                navigate('/registration-success');
            }
        } catch (error) {
            // Handle registration error
            console.error("Email registration error:", error);
        }
    }

    if (school?.email !== null && school?.email_verified === false) {
        return (
            <p className="text-center position-absolute top-50 start-50 translate-middle">
                To access your DASHBOARD, please verify your email.<br/> 
                A verification link has been sent to your registered email address. 
                If you don't see it, check your spam folder or request a new link.
            </p>
        )
    }

    return (
        <div className="container-fluid">
            <div className="row mt-5 justify-content-center">
                <div className="col-lg-4">
                    <div className="text-center">
                        <h3 >Hello, {school?.name}</h3>
                        <p>Please provide the email address you'd like to register for the school account</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {registrationError && <div className="text-danger">{registrationError}</div>}
                        
                        <div className="mb-3">
                            <input
                                className="form-control"
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100" disabled={registrationStatus === 'loading'}>
                            {registrationStatus === 'loading' ? 'Submitting...' : 'Add Email'}
                        </button>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default SchoolEmailRegistration;