import 'bootstrap/dist/css/bootstrap.min.css';
// import "./Payment.css";

import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function Payment() {
    const location = useLocation();
    const totalPrice = parseFloat(new URLSearchParams(location.search).get('totalPrice')).toFixed(2);
    const { userId } = useParams();
    const [clientSecret, setClientSecret] = useState("");
    const basketItems = useSelector((state) => state.basket.basket);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch(`/api/payment/${userId}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ totalPrice }),
        })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }, [userId, totalPrice]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="container-fluid">
            <div className="container py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="p-5 border mb-sm-3">
                                <h2>£{totalPrice}</h2>
                                {basketItems.map((basketItem) => (
                                    <>
                                    <p className="">{basketItem.item_name} / {basketItem.school_name}</p>
                                    {basketItem.to_removal && <p className="text-danger">item no longer available</p>}
                                    </>
                                ))}
                            </div>                         
                        </div>
                        <div className="col-lg-8">
                            <div className="p-5 border">
                                {clientSecret && (
                                    <Elements options={options} stripe={stripePromise}>
                                        <CheckoutForm />
                                    </Elements>
                                )}
                            </div>                         
                        </div>
                    </div>
                </div>    
            </div> 
        </div> 
    );
}
