import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching school earnings
export const fetchSchoolEarnings = createAsyncThunk('schoolEarnings/fetchSchoolEarnings', async (userId) => {
  const response = await fetch(`/api/orders/school-earnings/user/${userId}`);
  const data = await response.json();
  return data;
});

const schoolEarningsSlice = createSlice({
  name: 'schoolEarnings',
  initialState: { schoolEarnings: [], status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchoolEarnings.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(fetchSchoolEarnings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.schoolEarnings = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(fetchSchoolEarnings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default schoolEarningsSlice.reducer;
