import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForsSale
export const createConnectedAccount = createAsyncThunk('connectedAccount/createConnectedAccount', async (userId) => {
  let response;
  try {
    response = await fetch(`/api/payment/create-connected-account/user/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Connected account creation failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createConnectedAccount thunk', error);
    throw new Error(error.message || 'An error occurred during the API call');
  }
});

const createConnectedAccountSlice = createSlice({
  name: 'connectedAccount',
  initialState: { connectedAccount: null, status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createConnectedAccount.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(createConnectedAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.connectedAccount = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(createConnectedAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default createConnectedAccountSlice.reducer;
