import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching Stripe connected account
export const fetchConnectedAccount = createAsyncThunk('connectedAccount/fetchConnectedAccount', async (connectedAccountId) => {
  const response = await fetch(`/api/payment/connected-account/${connectedAccountId}`);
  const data = await response.json();
  return data;
});

const connectedAccountSlice = createSlice({
  name: 'connectedAccount',
  initialState: { connectedAccount: null, status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnectedAccount.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(fetchConnectedAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.connectedAccount = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(fetchConnectedAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default connectedAccountSlice.reducer;
