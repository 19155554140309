import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchItemsForSaleByUserId } from './itemsForSaleByUserIdSlice';

// Create an async thunk for fetching stalls
export const removeItemForSale = createAsyncThunk(
  'itemsForSale/removeItemsForSale', 
  async ({ itemForSaleId }, { dispatch } ) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.user.id;

    try {
      await fetch(`/api/items-for-sale/${itemForSaleId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      // If the deletion is successful, refetch the items
      dispatch(fetchItemsForSaleByUserId(userId));
    } catch (error) {
      console.error('Error removing item-for-sale:', error);
      throw error; // Propagate the error
    }
  }
);

const removeItemForSaleSlice = createSlice({
  name: 'itemForSale',
  initialState: { status: 'idle', error: null, itemForSale: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeItemForSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeItemForSale.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(removeItemForSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default removeItemForSaleSlice.reducer;
