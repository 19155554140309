import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForSale
export const fetchItemsForSale = createAsyncThunk('itemsForSale/fetchItemsForSale', async () => {
  const response = await fetch('/api/items-for-sale');
  const data = await response.json();
  return data;
});

const itemsForSaleSlice = createSlice({
  name: 'itemsForSale',
  initialState: { itemsForSale: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItemsForSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchItemsForSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Ensure itemsForSale is always an array
        state.itemsForSale = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchItemsForSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default itemsForSaleSlice.reducer;
