import React from 'react';

const PasswordResetRequestSuccess = () => {
    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1>Password Reset Request Successful</h1>
                <p>
                    A link to reset your password has been sent to your email. 
                    Please check your inbox and, if you don't see the email, 
                    also check your spam or junk folder. Follow the instructions in the email to complete the process.
                </p>
            </div>
        </div>
    );
};

export default PasswordResetRequestSuccess;
