import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchSchoolEarnings } from '../../redux/slices/schoolEarningsSlice';
import { fetchUserById } from '../../redux/slices/userByIdSlice';
import { fetchTransferFunds } from '../../redux/slices/transferFundsSlice';
import { fetchConnectedAccount } from '../../redux/slices/connectedAccountSlice';
// import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';
import { fetchPayouts } from '../../redux/slices/payoutsSlice';

import { checkStripeConnectedAccountStatus } from '../../utils/stripe'

import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';

const SchoolDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [schoolEarningToWithdraw, setSchoolEarningToWithdraw] = useState('0.00');
    const [withdrawnSchoolEarning, setWithdrawnSchoolEarning] = useState('0.00');
    const [showSuccessfulSchoolWithdrawal, setShowSuccessfulSchoolWithdrawal] = useState(false);
    const [showFailedSchoolWithdrawal, setShowFailedSchoolWithdrawal] = useState(false);
    const [transferFundsResult, setTransferFundsResult] = useState({});
    const [connectedAccountStatus, setconnectedAccountStatus] = useState('loading');
    
    const userById = useSelector((state) => state.userById.user);
    const schoolEarnings = useSelector((state) => state.schoolEarnings.schoolEarnings);
    const connectedAccount = useSelector((state) => state.connectedAccount.connectedAccount);
    // const accountLink = useSelector((state) => state.createAccountLink.accountLink);
    const payouts = useSelector((state) => state.payouts.payouts);

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;
    const connectedAccountId = userById?.stripe_connected_account_id;

    useEffect(() => {
        dispatch(fetchUserById(userId));
        dispatch(fetchSchoolEarnings(userId));
        if (connectedAccountId) {
            dispatch(fetchPayouts(connectedAccountId));
            dispatch(fetchConnectedAccount(connectedAccountId));
        }
    }, [dispatch, connectedAccountId, userId]);

    // useEffect(() => {
    //     if (connectedAccountId && connectedAccountStatus === 'enabled') {
    //         dispatch(createAccountLink(connectedAccountId));
    //     }
    // }, [dispatch, connectedAccountId, connectedAccountStatus]);

    useEffect(() => {
        const savedResult = localStorage.getItem(`transferFundsResult_${userId}`);
        if (savedResult) {
            setTransferFundsResult(JSON.parse(savedResult));
        }
    }, [userId]);

    // Memoizing effective school earnings
    const effectiveSchoolEarnings = useMemo(() => (
        schoolEarnings?.[0]?.donations?.filter(schoolEarning => (
            schoolEarning.donation_amount > 0
        )) || []
    ), [schoolEarnings]);

    const calculateSchoolEarningToWithdraw = useCallback(() => {
        if (Array.isArray(effectiveSchoolEarnings)) {
            const totalSchoolEarningToWithdraw = effectiveSchoolEarnings.filter(earning => earning.fund_transferred_to_school === false)
                .reduce((accumulator, current) => accumulator + parseFloat(current.donation_amount), 0).toFixed(2);
            if (schoolEarningToWithdraw !== totalSchoolEarningToWithdraw) {
                setSchoolEarningToWithdraw(totalSchoolEarningToWithdraw);
            }
        } else {
            if (schoolEarningToWithdraw !== '0.00') {
                setSchoolEarningToWithdraw('0.00');
            }
        }
    }, [effectiveSchoolEarnings, schoolEarningToWithdraw]);

    const calculateWithdrawnSchoolEarning = useCallback(() => {
        if (Array.isArray(effectiveSchoolEarnings)) {
            const totalWithdrawnSchoolEarning = effectiveSchoolEarnings.filter(earning => earning.fund_transferred_to_school === true)
                .reduce((accumulator, current) => accumulator + parseFloat(current.donation_amount), 0).toFixed(2);
            if (withdrawnSchoolEarning !== totalWithdrawnSchoolEarning) {
                setWithdrawnSchoolEarning(totalWithdrawnSchoolEarning);
            }
        } else {
            if (withdrawnSchoolEarning !== '0.00') {
                setWithdrawnSchoolEarning('0.00');
            }
        }
    }, [effectiveSchoolEarnings, withdrawnSchoolEarning]);

    useEffect(() => {
        calculateSchoolEarningToWithdraw();
        calculateWithdrawnSchoolEarning();
    }, [effectiveSchoolEarnings]);

    const totalSchoolEarning = () => {
        if (Array.isArray(effectiveSchoolEarnings)) { 
            return effectiveSchoolEarnings.reduce((accumulator, current) => accumulator + parseFloat(current.donation_amount), 0).toFixed(2);
        } else {
            return '0.00';
        }
    };

    useEffect(() => {
        const fetchConnectedAccountStatus = async () => {
            const status = await checkStripeConnectedAccountStatus(connectedAccount);
            setconnectedAccountStatus(status); // Update state once status is fetched
        };
        fetchConnectedAccountStatus(); // Call the function when the component mounts
    }, [connectedAccountId, connectedAccount]); // Dependency array ensures the effect runs when `connectedAccountId` changes

    const handleWithdrawSchoolEarnings = async () => {    
        try {
            const amount = (schoolEarningToWithdraw * 100).toFixed();
            const itemsForSaleIds = effectiveSchoolEarnings.filter(earning => earning.fund_transferred_to_school === false).map(earning => earning.item_for_sale_id);

            // Wait for the user data to be updated
            const updatedUserById = await dispatch(fetchUserById(userId));
            const newConnectedAccountId = updatedUserById.payload?.stripe_connected_account_id;

            if (!newConnectedAccountId) {
                console.log('Failed to create or fetch connected account.');
                return;
            }

            if (connectedAccountStatus === 'restricted') {
                navigate('/onboarding/pre');
                return;
            }

            // If onboarding is complete, proceed with withdrawal
            const resultAction = await dispatch(fetchTransferFunds({ userId, connectedAccountId, amount, itemsForSaleIds }));

            if (fetchTransferFunds.fulfilled.match(resultAction)) {
                await setSchoolEarningToWithdraw('0.00');
                await setWithdrawnSchoolEarning(totalSchoolEarning());
                await setShowSuccessfulSchoolWithdrawal(true);
                await localStorage.setItem(`transferFundsResult_${userId}`, JSON.stringify(resultAction.payload));
                const savedTransferFundsResult = localStorage.getItem(`transferFundsResult_${userId}`);
                await setTransferFundsResult(JSON.parse(savedTransferFundsResult));
    
            } else {
                await setShowFailedSchoolWithdrawal(true);
                throw new Error(resultAction.error.message || 'Transfer funds failed');
            }

        
        } catch (error) {
            console.error('Error during withdrawal process:', error.message);
            await setShowFailedSchoolWithdrawal(true);
            // Provide user-friendly error handling here
        }
    };

    const latestTransferFundsResult = transferFundsResult && (new Date(transferFundsResult.created_at * 1000) >= new Date().setDate(new Date().getDate() - 1));
    let payoutsInTransit = [];
    if (!payouts.error) {
        payoutsInTransit = payouts.filter(payout => new Date(payout.arrival_date * 1000) >= new Date());
    }
    const isPayoutInTransit = payoutsInTransit.length > 0;

    return (
        <div className="container">
            <h2 className="my-5">{schoolEarnings[0]?.school_name}</h2>
            {connectedAccountStatus === 'enabled' ? (
                // <p className="alert alert-warning" role="alert">
                //     This account requires verification. <a href={`${accountLink.url}`}>Click here</a> to avoid withdrawal interruption.
                // </p>
                <p></p>
            ):(
                <p></p>
            )}
            <section>
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Total Donations Received</th>
                            <th scope="col">withdrawn</th>
                            <th scope="col">to withdraw</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>£{totalSchoolEarning()}</td>
                            <td>£{withdrawnSchoolEarning}</td>
                            <td>
                                <span className={`${schoolEarningToWithdraw === '0.00' ? '' : 'bg-success text-white p-1 fw-bold'}`}>£{schoolEarningToWithdraw}</span>
                            </td>
                            <td>
                                <button onClick={handleWithdrawSchoolEarnings} className={`${schoolEarningToWithdraw === '0.00' ? 'disabled' : ''} btn btn-outline-primary btn-sm`}>Withdraw</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {latestTransferFundsResult && 
                    <p className="text-bg-warning">
                        {`The latest withdrawal of £${transferFundsResult.amount / 100} 
                        on ${new Date(transferFundsResult.created_at * 1000).toLocaleDateString('en-GB')} 
                        is expected to reach your bank account soon.`}
                    </p>
                }
                {isPayoutInTransit && 
                    <>
                        {payoutsInTransit.map(payout => (
                            <p 
                                key={payout.id} 
                                className="text-bg-success"
                            >
                                {`The amount of £${payout.amount / 100} 
                                is expected to reach your bank account on 
                                ${new Date(payout.arrival_date * 1000).toLocaleDateString('en-GB')}.`}
                            </p>
                        ))}
                    </>
                }
                <Modal show={showSuccessfulSchoolWithdrawal} onHide={() => setShowSuccessfulSchoolWithdrawal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Withdrawal successful!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Your withdrawal amount will be transferred soon.</Modal.Body>
                    </Modal>
                    <Modal show={showFailedSchoolWithdrawal} onHide={() => setShowFailedSchoolWithdrawal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Oops, Something went wrong!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Please try again later.</Modal.Body>
                    </Modal>
            </section>
            <section>
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Donor</th>
                            <th scope="col">Donation Received</th>
                        </tr>
                    </thead>
                    <tbody>
                        {effectiveSchoolEarnings.map(earning => (
                            <tr key={earning.item_for_sale_id}>
                                <td>{new Date(earning.created_at).toLocaleDateString('en-GB')}</td>
                                <td>{earning.donor}</td>
                                <td>£{earning.donation_amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    );
};

export default SchoolDashboard;
