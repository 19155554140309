import React, { useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSchoolBySchoolUserId } from '../../redux/slices/schoolBySchoolUserIdSlice';

const PasswordUpdateSuccess = () => {
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id
    const isSchoolUser = userData?.user?.school_id !== null;

    // Update hasTempPassword on App.js
    useEffect(() => {
        if (userId && isSchoolUser) {
          dispatch(fetchSchoolBySchoolUserId(userId));
        }
      }, [dispatch, userId]);

    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1>Password Update Successful</h1>
                <p>
                    Your password has been successfully updated
                </p>
                <Link to={isSchoolUser ? "/school-dashboard" : "/dashboard"} className="btn btn-primary">Go to Dashboard</Link>
            </div>
        </div>
    );
};

export default PasswordUpdateSuccess;
