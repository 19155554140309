import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchItemsForSale } from './itemsForSaleSlice';

// Create an async thunk for fetching ItemForSale
export const createItemForSale = createAsyncThunk('itemForSale/createItemForSale', async (formData, { dispatch }) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.user.id;

  let response;
  try {
    response = await fetch(`/api/items-for-sale/user/${userId}`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'ItemForSale creation failed');
    }

    const data = await response.json();
    dispatch(fetchItemsForSale())
    return data;
  } catch (error) {
    console.error('Error in createItemForSale thunk', error);
    throw new Error(error.message || 'An error occurred during the API call');
  }
});

const createItemForSaleSlice = createSlice({
  name: 'itemForSale',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createItemForSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createItemForSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.itemForSale = action.payload;
      })
      .addCase(createItemForSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default createItemForSaleSlice.reducer;

