import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching stalls
export const fetchEarnings = createAsyncThunk('earnings/fetchEarnings', async (userId) => {
  const response = await fetch(`/api/orders/earnings/user/${userId}`);
  const data = await response.json();
  return data;
});

const earningsSlice = createSlice({
  name: 'earnings',
  initialState: { earnings: [], status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEarnings.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(fetchEarnings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.earnings = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(fetchEarnings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default earningsSlice.reducer;
