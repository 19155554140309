import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterSchools } from '../../redux/slices/filterSchoolsSlice';
import Autocomplete from './Autocomplete';

const SearchSchool = ({ onSchoolChange }) => {
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState('');
    const filteredSchools = useSelector((state) => state.filterSchools.filteredSchools);

    // Fetch schools as the user types
    useEffect(() => {
        if (searchQuery.trim()) {
            dispatch(filterSchools(searchQuery.trim())); // Fetch based on the search query
        }
    }, [dispatch, searchQuery]); // Trigger on change in search query 

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };
    
    const handleSchoolSelect = (selectedSchool) => {
        setSearchQuery(selectedSchool); // Update the input field
        onSchoolChange(selectedSchool); // Notify parent component
    };

    return (
        <div>
            <Autocomplete 
                suggestions={filteredSchools.map(
                (school) => `${school.name}, ${school.town}, ${school.postcode}`
                )}
                onInputChange={handleInputChange} // Change handler for the input
                onSelect={handleSchoolSelect} // Handler for selecting a suggestion
                searchTerm={searchQuery} // Pass the current search query to the component
            />
        </div>
    );
};

export default SearchSchool;
