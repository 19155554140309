import React from 'react';
import { Link } from 'react-router-dom';

const PasswordResetSuccess = () => {
    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1>Password Reset Successful</h1>
                <p>
                    Your password has been successfully reset.<br/>
                    You can now log in to your account using your new password.
                </p>
                <Link to="/login" className="btn btn-primary">Login</Link>
            </div>
        </div>
    );
};

export default PasswordResetSuccess;
